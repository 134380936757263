import React from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import './confirm-model-component.scss';

const ConfirmModelComponent = ({ isOpen, toggleModal, deleteUser, title, text }) => {
    console.log(isOpen, 'isOpen')
    return (
        <Modal
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
        >
            <ModalHeader className="text-dark" toggle={() => toggleModal(!isOpen)}>
                Confirmation
          </ModalHeader>
            <ModalBody className="text_color">
                <h5 style={{color: "#000"}}>{title}</h5>
                <span style={{color: "#000"}}>{text}</span>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => { toggleModal(!isOpen); deleteUser(); }}>
                    Accept
                </Button>{" "}
            </ModalFooter>
        </Modal>
    )
}
export default ConfirmModelComponent;
