import React, { useEffect, useState } from "react"
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Media,
    Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
import { Link } from "react-router-dom"
import { getNotification, updateNotificationReadAllStatusApi, updateNotificationReadStatusApi } from 'services/other';
import { formatDateWithFormat } from 'utility/format';
import { getToken, sessionCheck } from 'utility/store';
import './notification-dropdown-component.scss';

const _getToken = getToken();
const NotificationDropdownComponent = () => {
    const [notification, setNotification] = useState([]);
    const [count, setCount] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const getNotificationFun = () => {
        let params = {
            "id": _getToken?._id,
            "notification_for": _getToken?.admin_type === 'designer' ? 'designer' : 'admin',
            "page": 0,
            "size": 10
        }
        getNotification(params).then(({ data }) => {
            sessionCheck(data)
            if (data.status_code === 200) {
                setNotification(data ? data.notification : [])
                setCount(data ? data.count : 0)
            } else {
            }
        }).catch((err) => {
            return null
        })
    }

    const readSingleNotifcation = (id) => {
        let params = {
            "id": id,
        }
        updateNotificationReadStatusApi(params).then(({ data }) => {
            sessionCheck(data)
            // getNotificationFun()
        }).catch((err) => {
            return null
        })
    }

    const readAllNotifcation = () => {
        let params = {
            "id": _getToken?._id,
            "notification_for": _getToken?.admin_type === 'designer' ? 'designer' : 'admin'
        }
        updateNotificationReadAllStatusApi(params).then(({ data }) => {
            sessionCheck(data)
            // getNotificationFun()
        }).catch((err) => {
            return null
        })
    }

    // const messaging = window.firebase.messaging();
    // messaging.onMessage((payload) => {
    //     console.log('Message received. ', payload);
    // }); 
    useEffect(getNotificationFun, [dropdownOpen]);
    return (
        <UncontrolledDropdown
            tag="li"
            className="dropdown-notification nav-item"
            isOpen={dropdownOpen} toggle={toggle}
        >
            <DropdownToggle
                tag="a"
                className="nav-link nav-link-label">
                <Icon.Bell size={21} />
                <Badge pill color="primary" className="badge-up">
                    {" "}
                    {count}{" "}
                </Badge>
            </DropdownToggle>
            <DropdownMenu tag="ul" right className="dropdown-menu-media">
                <li className="dropdown-menu-header">
                    <div className="dropdown-header mt-0">
                        <h3 className="text-white">{count} New</h3>
                        <span className="notification-title">App Notifications</span>
                    </div>
                </li>
                <PerfectScrollbar
                    className="media-list overflow-hidden position-relative"
                    options={{
                        wheelPropagation: false
                    }}>
                    {
                        notification.length ?
                            notification.map((o, i) => {
                                if (i < 5) {
                                    return (
                                        <Link to={o.order_details && o.order_details[0] && o.order_details[0].order_type == 'kitchen'? `/manage_orders/orders/profile/${o.order_id}` : `/manage_orders/otherorders/profile/${o.order_id}`}
                                            onClick={() => readSingleNotifcation(o._id)}>
                                            <div className={o.isRead ?
                                                'd-flex justify-content-between n-notification-read n-bb' :
                                                'd-flex justify-content-between n-bb'}>
                                                <Media className="d-flex align-items-start">
                                                    <Media left href="#">
                                                        <Icon.PlusSquare
                                                            className="font-medium-5 primary"
                                                            size={21}
                                                        />
                                                    </Media>
                                                    <Media body>
                                                        <Media heading className="primary media-heading" tag="h6">
                                                            {o.notification_title}
                                                        </Media>
                                                        <p className="notification-text">
                                                            {o.notification_content}
                                                        </p>
                                                    </Media>
                                                    <small>
                                                        <time
                                                            className="media-meta"
                                                            dateTime="2015-06-11T18:29:20+08:00"
                                                        >
                                                            {formatDateWithFormat(o.created_on, "Do MMM YYYY")}
                                                        </time>
                                                    </small>
                                                </Media>
                                            </div>
                                        </Link>
                                    )
                                }
                            })
                            : ''
                    }

                </PerfectScrollbar>
                <li className="dropdown-menu-footer">
                    <DropdownItem tag="a" className="p-1 text-center">
                        <Link to='/manage/notifications'
                            onClick={() => readAllNotifcation()}>
                            <span className="align-middle">Read all notifications</span>
                        </Link>
                    </DropdownItem>
                </li>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}
export default NotificationDropdownComponent;
