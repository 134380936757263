
import React, { useState } from "react";
import { Button, Progress } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { XCircle } from "react-feather";
import { toast } from "react-toastify";
import { File } from "react-feather";
import { uploadFile } from "services/file";
import {
    NiuliLoader
} from 'views/components/atoms';

//css
import './file-upload-component.scss'

const FileUploadComponent = ({ title, accept, icon, files, fileFor, updateDocLink, multiple,isCustomButton=false }) => {

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    // react drop zone
    const { getInputProps, open } = useDropzone({
        accept: accept,
        maxFiles: multiple ? 5 : 1,
        multiple: multiple,
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            setLoading(true);
            if (accept === ".mp4") {
                (new window.VimeoUpload({
                    name: 'niuli',
                    description: 'videoDescription',
                    private: false,
                    file: acceptedFiles[0],
                    token: 'abafc2c4009b5dcd495a6d713d8193bb',
                    upgrade_to_1080: false,
                    onError: function (data) {
                        toast.error(`Error while uploading file! Please try again later.`)
                        setLoading(false);
                    },
                    onProgress: function (data) {
                        setProgress(Math.floor((data.loaded / data.total) * 100))
                        console.log(data, 'Vimeo onProgress')
                    },
                    onComplete: function (videoId) {
                        let videoIdArr = [videoId]
                        let concatedArrayVid = videoIdArr.concat(files);
                        updateDocLink(fileFor, concatedArrayVid);
                        console.log(concatedArrayVid, 'Vimeo onComplete')
                        setLoading(false);
                    }
                })).upload()
            } else {
                const getData = async () => {
                    return Promise.all(acceptedFiles.map(file => anAsyncFunction(file)))
                }

                const anAsyncFunction = async file => {
                    return functionWithPromise(file)
                }

                const functionWithPromise = async file => {
                    return Promise.resolve(uploadFile(file, fileFor))
                }
                getData().then(data => {
                    //remove undefiend in array
                    let properArr = data.filter(o => {
                        if (typeof o !== 'undefined') {
                            return o;
                        } else {
                            toast.error(`Error while uploading file`)
                            return false;
                        }
                    })
                    let concatedArray = properArr.concat(files);
                    updateDocLink(fileFor, concatedArray);
                    setLoading(false);
                })
            }

        }
    })
    const thumbs = files.length ? files.map((file) => (
        <div className="fu-img-preview" key={file}>
            <XCircle size={20} className="fu-delete-img" onClick={() => removeFile(file)} />
            {
                accept === "image/*" ? <img src={file} className="dz-img" alt={file} />
                    :
                    accept === ".mp4" ?
                        // <video width="150" height="100" controls>
                        //     <source src={file} type="video/mp4" />
                        // </video>
                        <iframe src={`https://player.vimeo.com/video/${file}`}
                            width="400"
                            height="300"
                            frameborder="0"
                            allowfullscreen ></iframe>
                        :
                        <p className="fu-pdf-name">
                            <File color="#E56353" />
                            {file.split('/')[(file.split('/').length - 1)]}
                        </p>
            }
        </div>
    )) : ''

    const disableButton = (type) => {
        switch (type) {
            case 'profile_image':
                return files.length >= 1;
            default:
                return false;
        }

    }

    const removeFile = (fileLink) => {
        const index = files.indexOf(fileLink);
        if (index > -1) {
            files.splice(index, 1);
        }
        updateDocLink(fileFor, files,true);
    }

    return (
        <section>
            <input {...getInputProps()} />
            {
                isCustomButton?
                <span onClick={open} disabled={disableButton(fileFor)}>Upload Bulk Data</span>
                :
                <Button.Ripple
                    color="primary"
                    outline
                    className="my-1 um-upload-btn"
                    onClick={open}
                    disabled={disableButton(fileFor)}
                >
                    {icon} {title}
                </Button.Ripple>
            }
            <aside className="fu-preview-container">
                {
                    !loading ?
                        <>{thumbs}</>
                        :
                        accept === ".mp4" ?
                            <div className="fu-progess-div">
                                <Progress value={progress} className="progress-md" />
                            </div>
                            :
                            <div className="fu-loader-div">
                                <NiuliLoader />
                            </div>
                }
            </aside>
        </section>
    )
}

export default FileUploadComponent;