import React, { useState } from "react";
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
} from "reactstrap"
import { ChevronDown } from "react-feather"
import classnames from "classnames"
import './collapsible-component.scss';

const CollapsibleComponent = ({ ticket }) => {
    const [status, setStatus] = useState('Opened');
    const [collapse, setCollapse] = useState(false);
    return (
        <Row className="nu-mb10">
            <Col className="card-action">
                <Card
                    className={classnames({
                        "card-collapsed": status === "Closed",
                        "card-shown": status === "Opened",
                        closing: status === "Closing...",
                        opening: status === "Opening..."
                    })}
                >
                    <CardHeader>
                        <CardTitle>{ticket.type}</CardTitle>
                        <ChevronDown
                            className="collapse-icon"
                            size={15}
                            onClick={() => setCollapse(!collapse)}
                        />
                    </CardHeader>
                    <Collapse
                        isOpen={collapse}
                        onExited={() => setStatus("Closed")}
                        onEntered={() => setStatus("Opened")}
                        onExiting={() => setStatus("Closing...")}
                        onEntering={() => setStatus("Opening...")}
                    >
                        <CardBody>
                            <p>
                                {ticket.ticket_description}
                            </p>
                        </CardBody>
                    </Collapse>
                </Card>
            </Col>
        </Row>
    )
}
export default CollapsibleComponent;
