const initialState = {
    list: [],
    total: 0,
    searchKey: '',
    detail: ''
};
const ManagePromotionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_PROMOTION_SEARCHKEY':
            return {
                ...state,
                searchKey: action.data
            };
        case 'UPDATE_PROMOTION_LIST':
            return {
                ...state,
                total: action.data.total,
                list: [...action.data.list]
            };
        case 'UPDATE_PROMOTION_PROFILE':
            return {
                ...state,
                detail: action.data
            };
        default:
            return state;
    }
};

export default ManagePromotionReducer;