const initialState = {
    list: []
}

const ManageAnalyticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ANALYTICS':
            console.log(action)
            return {
                ...state,
                list: [...action.data.records]
            }
        default:
            return state;
    }
}

export default ManageAnalyticsReducer;