import axios from 'axios'

import { getToken } from "utility/store";

const addToken = config => {
  const token = getToken()
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.authorization = `${token.token}`
  }
  return config
}

const createClient = () => {

  // while working use below set of code

  // const client = axios.create({ baseURL: `https://oz2b40r5u6.execute-api.ap-south-1.amazonaws.com/dev` })
  
  // client.interceptors.request.use(addToken, Promise.reject)

  // return client

// while build use below set of code

if (process.env.REACT_APP_STAGE === "build for dev") {
    const client = axios.create({ baseURL: `https://api.dev.niulihome.com` })
    client.interceptors.request.use(addToken, Promise.reject)
    return client
 } 
 else if (process.env.REACT_APP_STAGE === "build for QA") {
  const client = axios.create({ baseURL: `https://api.qa.niulihome.com` })
  client.interceptors.request.use(addToken, Promise.reject)
  return client
 } 
 else {
  const client = axios.create({ baseURL: `https://api.niulihome.com` })
  client.interceptors.request.use(addToken, Promise.reject)
  return client
 }


//  old config
// if (process.env.REACT_APP_STAGE === "build for dev") {
//     const client = axios.create({ baseURL: `https://oz2b40r5u6.execute-api.ap-south-1.amazonaws.com/dev` })
//     client.interceptors.request.use(addToken, Promise.reject)
//     return client
//  } 
//  else if (process.env.REACT_APP_STAGE === "build for QA") {
//   const client = axios.create({ baseURL: `https://b03qxj9nsi.execute-api.ap-south-1.amazonaws.com/qa` })
//   client.interceptors.request.use(addToken, Promise.reject)
//   return client
//  } 
//  else {
//   const client = axios.create({ baseURL: `https://m6llxu78ng.execute-api.ap-south-1.amazonaws.com/prod` })
//   client.interceptors.request.use(addToken, Promise.reject)
//   return client
//  }

}

const http = createClient()

export default http
