import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import dataList from "./data-list/";
import ManageUserReducer from "./manage-user";
import LoadingStateReducer from "./loading-state";
import ModelStateReducer from "./model-state";
import ManageOrderReducer from "./manage-order";
import ManageProductReducer from "./manage-product";
import ManagePromotionReducer from "./manage-promotion";
import ManageHomePageReducer from "./manage-homepage";
import ManageReportReducer from "./manage-report"
import ManageAnalyticsReducer from "./manage-analytics";
import ManageTimeZoneReducer from "./timeZone";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  userData: ManageUserReducer,
  loadingState: LoadingStateReducer,
  modelState: ModelStateReducer,
  orderData: ManageOrderReducer,
  productData: ManageProductReducer,
  promotionData: ManagePromotionReducer,
  homepageData: ManageHomePageReducer,
  reportData: ManageReportReducer,
  analytics: ManageAnalyticsReducer,
  timeZone: ManageTimeZoneReducer,
})

export default rootReducer
