const initialState = {
    list: [],
    total: 0,
    searchKey: '',
    detail: '',
    orderCheckListStatus: [],
    otherOrderCheckListStatus:[],
    cities:[]
};
const ManageOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_ORDER_SEARCHKEY':
            return {
                ...state,
                searchKey: action.data
            };
        case 'UPDATE_ORDER_LIST':
            return {
                ...state,
                total: action.data.total,
                list: [...action.data.list],
                cities: [...action.data.cities]
            };
        case 'UPDATE_ORDER_PROFILE':
            return {
                ...state,
                detail: action.data
            };
        case 'UPDATE_ORDER_CHECKLIST_STATUS':
            return {
                ...state,
                orderCheckListStatus: action.data
            };
        case 'UPDATE_OTHER_ORDER_CHECKLIST_STATUS':
            return {
                ...state,
                otherOrderCheckListStatus: action.data
            };
        default:
            return state;
    }
};

export default ManageOrderReducer;