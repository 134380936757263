import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { uploadFile } from "services/file";
import attachfile from "assets/icons/chat/attach_fileapp.png";
import sendmsgbtn from "assets/icons/chat/send_msgapp.png";
import ProfileImage from "assets/icons/chat/msgimg.png";
import PreviewImage from "assets/icons/chat/preview.svg";
import DeleteImage from "assets/icons/chat/trash.svg";
import DownloadImage from "assets/icons/chat/download.png";
import DocImage from "assets/icons/chat/doc.png";
import PdfImage from "assets/icons/chat/pdf.png";
import ExcelImage from "assets/icons/chat/xls.png";
import FileTypeImage from "assets/img/svg/File.svg";
import PubNub from "pubnub";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { PubNubProvider, PubNubConsumer } from "pubnub-react";
import { ClipLoader } from "react-spinners";
import config from "../../../../configs/properties";
import { addAdminChatMessageApi } from "services/other";
import "./chat-box-component.scss";

const pubnub = new PubNub({ ...config.PUBNUB });
const ChatBoxComponent = ({
  chatId,
  order_Id,
  orderID,
  chatInfo = { chatInfo },
  activateSendInput,
}) => {
  const channels = [chatId];
  const dispatch = useDispatch();
  const [messages, addMessage] = useState([]);
  const [image, setImage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");

  const sendMessage = (message, type, fileType) => {
    console.log(channels[0], "channels[0]");
    if (message.message_text !== "" || message.message_url) {
      pubnub.publish(
        {
          channel: channels[0],
          message: {
            message_type: type,
            ...message,
            member_type: "admin",
            member_name: localStorage.getItem("name"),
            profile_img: ProfileImage,
            fileType: fileType,
            uuid: localStorage.getItem("_id"),
            pn_gcm: {
              notification: {
                title: "ORDER NO" + orderID,
                body:
                  type == "chat_message"
                    ? message.message_text
                    : type == "image_message"
                    ? "Image Message"
                    : "File Message",
                image:
                  "https://niuli-images.s3.ap-south-1.amazonaws.com/Customer.png",
                sound: "default",
              },
              data: {
                system_generated_order_id: orderID,
                type: "admin_chat",
                order_data: {
                  chat_type: "admin_chat",
                  admin_id: localStorage.getItem("_id"),
                  order_id: order_Id,
                  order_details: [
                    {
                      order_id: orderID,
                    },
                  ],
                  admin_details: [
                    {
                      _id: localStorage.getItem("_id"),
                      name: localStorage.getItem("name"),
                      admin_type: localStorage.getItem("admin_type"),
                    },
                  ],
                },
                order_id: order_Id,
                channel_id: channels[0],
              },
            },
          },
        },
        function (status, response) {
          if (status.statusCode == 200) {
            let params = {
              order_id: order_Id,
              adminId: localStorage.getItem("_id"),
              admin_chat_with: chatInfo.type.toLowerCase(),
              admin_chat_with_id: chatInfo.id,
              message_type: type,
              member_type: "admin",
              member_name: localStorage.getItem("name"),
              message_time_token: response.timetoken,
            };

            if (type == "chat_message") {
              params.message_text = message.message_text;
            } else {
              params.message_url = message.message_url;
            }
            addAdminChatMessageApi(params)
              .then(({ data }) => {
                return null;
              })
              .catch((err) => {
                return null;
              });
          }
          setMessage("");
        }
      );
    }
  };

  const openNotificationWithIcon = (type, msg, des) => {
    alert(msg);
  };

  useEffect(() => {
    let isMounted = true;

    pubnub.history(
      {
        channel: channels[0],
        count: 100, // how many items to fetch
        stringifiedTimeToken: true, // false is the default
      },
      function (status, response) {
        if (isMounted && response?.messages) {
          let messageHistroy = response.messages?.map((msg) => msg);
          addMessage(messageHistroy);
          scrollToBottom();
        }
      }
    );

    return () => {
      isMounted = false; // Cleanup function to avoid state update on unmounted component
    };
  }, []); 

  const scrollToBottom = () => {
    var objDiv = document.getElementsByClassName("statussec_oneniuli");
    if (objDiv.length) {
      objDiv[0].scrollTop = objDiv[0].scrollHeight;
      if (objDiv.length > 1) {
        objDiv[1].scrollTop = objDiv[1].scrollHeight;
      }
    }
  };
  const sendFile = async (e) => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].size > 10485760) {
        openNotificationWithIcon(
          "warning",
          "File upload failed",
          "File/Image size is too big"
        );
      } else {
        setLoader(true);
        let sendFileName = e.target.files[0];
        let obj = {
          file: sendFileName,
        };
        const fileUrl = await uploadFile(sendFileName, "chat");
        console.log(fileUrl, "fileUrlfileUrl");
        let fileType =
          sendFileName.type.substr(0, sendFileName.type.indexOf("/")) ===
          "image"
            ? "image_message"
            : "file_message";
        let type = sendFileName.type.toString();
        if (fileUrl !== undefined) {
          sendMessage({ message_url: fileUrl }, fileType, type);
          setLoader(false);
        } else {
          openNotificationWithIcon(
            "error",
            "File upload failed",
            "Error while uploading file/image"
          );
        }
      }
    } else {
      openNotificationWithIcon(
        "error",
        "File upload failed",
        "Error while uploading file/image"
      );
    }
  };
  const keyPress = (e) => {
    const check = message.trim(" ");
    if (check.length < 1) {
      setMessage("");
      return;
    }

    if (e.keyCode == 13 && message !== "") {
      sendMessage({ message_text: message }, "chat_message", "text");
    }
  };

  const openLightbox = (set, image) => {
    setIsOpen(set);
    setImage(image);
  };

  const deleteImage = (token) => {
    console.log(channels[0], token, "token");

    pubnub.deleteMessages(
      {
        channel: ["EVENING_TEST1"],
        start: "15998346109038169",
        end: "15998346109038170",
      },
      function (status, response) {
        console.log(status, response);
      }
    );
  };

  return (
    <React.Fragment>
      <PubNubProvider client={pubnub}>
        <div className="app_stdniulimsg">
          <PubNubConsumer>
            {(client) => {
              // console.log("hh")
              client.addListener({
                message: function (msg) {
                  if (activateSendInput && chatId === msg.channel) {
                    addMessage([
                      ...messages,
                      { entry: msg.message, timetoken: msg.timetoken },
                    ]);
                    scrollToBottom();
                  }
                },
              });
              client.subscribe({ channels });
            }}
          </PubNubConsumer>
          <div className="statussec_oneniuli" id="chat_div">
            {messages.length ? (
              messages.map((message, messageIndex) => {
                return message.entry.message_type == "chat_message" ? (
                  message.entry.member_type == "customer" ? (
                    <div className="app_std" key={`message-${messageIndex}`}>
                      <div className="app_stdmsgchat">
                        <span className="chat_innerwrap">
                          {message.entry.message_text}
                        </span>
                        <span className="chat_innerwrapper">
                          {moment(parseInt(message.timetoken) / 1e4).fromNow()}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="app_std" key={messageIndex}>
                      <div className="app_stdmsgchatleft">
                        <span className="chat_smallimg lazyload">
                          <img
                            src={message.entry.profile_img}
                            style={{
                              height: "25px",
                              width: "25px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            alt="profile-img"
                          />
                        </span>
                        <div className="designerdetails_chat">
                          <span className="msgchatprf_name">
                            {" "}
                            {message.entry.member_name},{" "}
                            {message.entry.member_type}
                          </span>
                          <span className="chat_innerwrapleft">
                            {message.entry.message_text}
                          </span>
                          <span className="chat_innerwrappeleftr">
                            {moment(
                              parseInt(message.timetoken) / 1e4
                            ).fromNow()}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                ) : message.entry.message_type == "image_message" ||
                  message.entry.message_type == "file_message" ? (
                  message.entry.member_type == "customer" ? (
                    <div className="customer-image-div">
                      {loader == true && messageIndex == messages.length - 1 ? (
                        <div className="loader_right">
                          <ClipLoader color={"#242E49"} loading={loader} />
                        </div>
                      ) : (
                        <span>
                          <div className="image">
                            {console.log(
                              message.entry,
                              "messagemessagemessage"
                            )}
                            <img
                              src={
                                message.entry.message_type == "image_message"
                                  ? message.entry.message_url
                                  : message.entry.fileType.split("/")[1] ==
                                    "pdf"
                                  ? PdfImage
                                  : message.entry.fileType.split("/")[1] ==
                                    "msword"
                                  ? DocImage
                                  : message.entry.fileType.split("/")[1] ==
                                    "xlsx"
                                  ? ExcelImage
                                  : FileTypeImage
                              }
                              alt="img"
                              className="customer-image lazyload"
                            />

                            {message.entry.message_type == "image_message" ? (
                              <span>
                                <div
                                  className="preview-class"
                                  onClick={() =>
                                    openLightbox(
                                      true,
                                      message.entry.message_url
                                    )
                                  }
                                >
                                  {" "}
                                  <img src={PreviewImage} alt="preview" />
                                </div>

                                <a href={message.entry.message_url} download>
                                  {" "}
                                  <div className="update">
                                    {" "}
                                    <img src={DownloadImage} alt="preview" />
                                  </div>
                                </a>
                              </span>
                            ) : (
                              <a href={message.entry.message_url} download>
                                {" "}
                                <div className="update">
                                  {" "}
                                  <img src={DownloadImage} alt="preview" />
                                </div>
                              </a>
                            )}
                            <div
                              className={
                                message.entry.message_type == "image_message"
                                  ? "delete"
                                  : "delete-file"
                              }
                              onClick={() => deleteImage(message.timetoken)}
                            >
                              <img src={DeleteImage} alt="delete" />
                            </div>
                          </div>
                          <span
                            className="chat_innerwrapper"
                            style={{ width: "100%", marginTop: "10px" }}
                          >
                            {moment(
                              parseInt(message.timetoken) / 1e4
                            ).fromNow()}
                          </span>
                        </span>
                      )}
                      {isOpen && (
                        <Lightbox
                          mainSrc={image}
                          clickOutsideToClose={false}
                          onCloseRequest={() => openLightbox(false, "")}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="delivery-image-div">
                      <div className="image" style={{ float: "left" }}>
                        <img
                          src={
                            message.entry.message_type == "image_message"
                              ? message.entry.message_url
                              : message.entry.fileType.split("/")[1] == "pdf"
                              ? PdfImage
                              : message.entry.fileType.split("/")[1] == "msword"
                              ? DocImage
                              : message.entry.fileType.split("/")[1] == "xlsx"
                              ? ExcelImage
                              : FileTypeImage
                          }
                          alt="img"
                          className="customer-image lazyload"
                        />
                        {message.entry.message_type == "image_message" ? (
                          <span>
                            <div
                              className="preview-class"
                              onClick={() =>
                                openLightbox(true, message.entry.message_url)
                              }
                            >
                              {" "}
                              <img src={PreviewImage} alt="preview" />
                            </div>
                            <a href={message.entry.message_url} download>
                              {" "}
                              <div className="update">
                                {" "}
                                <img src={DownloadImage} alt="preview" />
                              </div>
                            </a>
                          </span>
                        ) : (
                          <a href={message.entry.message_url} download>
                            {" "}
                            <div className="update">
                              {" "}
                              <img src={DownloadImage} alt="preview" />
                            </div>
                          </a>
                        )}
                        <div
                          className="delete"
                          onClick={() => deleteImage(message.timetoken)}
                        >
                          <img src={DeleteImage} alt="delete" />
                        </div>
                      </div>
                      <span
                        className="chat_innerwrapper"
                        style={{ width: "100%", marginTop: "10px" }}
                      >
                        {moment(parseInt(message.timetoken) / 1e4).fromNow()}
                      </span>
                      {isOpen && (
                        <Lightbox
                          mainSrc={image}
                          clickOutsideToClose={false}
                          onCloseRequest={() => openLightbox(false, "")}
                        />
                      )}
                    </div>
                  )
                ) : (
                  <div className="system-message">
                    {message.entry.message_text}
                  </div>
                );
              })
            ) : (
              <p className="no-msg">No messages</p>
            )}
          </div>
          {activateSendInput ? (
            <div className="app_stdsendmsgchat">
              <input
                className="typechatmsg"
                type="text"
                placeholder="Type your message here"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={keyPress}
              />
              <span className="file_appniuli">
                <label htmlFor="update-image" color="primary">
                  <img src={attachfile} alt="attach" />
                  <input
                    type="file"
                    accept=".pdf,.doc,image/*, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="update-image"
                    hidden
                    onChange={(e) => sendFile(e)}
                  />
                </label>
              </span>

              <span
                className="send_appniuli"
                onClick={(e) => {
                  e.preventDefault();
                  sendMessage(
                    { message_text: message },
                    "chat_message",
                    "text"
                  );
                }}
              >
                <img src={sendmsgbtn} alt="send" />
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </PubNubProvider>
    </React.Fragment>
  );
};

export default ChatBoxComponent;
