import { Clock as ClockIcon } from "react-feather";
import React, { useState, useEffect } from "react";
import { formatDateWithFormat } from "../../../../utility/format";

const Clock = () => {
  const format = "hh:mm A";
  const getCurrentTime = () =>
    formatDateWithFormat(new Date().toISOString(), format);

  const [ctime, setTime] = useState(getCurrentTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getCurrentTime());
    }, 0); // update every 60 seconds

    return () => clearInterval(interval); // cleanup interval on unmount
  }, []);

  return (
    <div className="d-flex justify-content-start align-items-center mr-1">
      <ClockIcon size={25} className="d-none d-md-inline mr-1" />
      {ctime} KSA
    </div>
  );
};

export default Clock;
