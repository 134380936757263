const initialState = {
  list: [],
  total: 0,
  searchKey: '',
  detail: '',
  masterDetail: '',
  designerList: [],
  fitterList: [],
  deliveryAgentList: [],
  areaManagerList: [],
  userIdObj: ''
};
const ManageUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_USER_SEARCHKEY':
      return {
        ...state,
        searchKey: action.data
      };
    case 'UPDATE_USER_LIST':
      return {
        ...state,
        total: action.data.total,
        list: [...action.data.list]
      };
    case 'UPDATE_USER_PROFILE':
      return {
        ...state,
        detail: action.data
      };
    case 'UPDATE_USER_MASTER_PROFILE':
      return {
        ...state,
        masterDetail: action.data
      };
    case 'UPDATE_ACTIVE_DESIGNER_LIST':
      return {
        ...state,
        designerList: action.data
      };
    case 'UPDATE_ACTIVE_FITTER_LIST':
      return {
        ...state,
        fitterList: action.data
      };
    case 'UPDATE_ACTIVE_DELIVERY_PERSON_LIST':
      return {
        ...state,
        deliveryAgentList: action.data
      };
    case 'UPDATE_ACTIVE_AREA_MANAGER_LIST':
      return {
        ...state,
        areaManagerList: action.data
      };
    case 'UPDATE_USER_ID':
      return {
        ...state,
        userIdObj: action.data
      };
    default:
      return state;
  }
};

export default ManageUserReducer;