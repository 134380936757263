const initialState = {
    timeZoneList: [
        {
            country_code:"SA",
            country_name:"Saudi Arabia",
            time_zone:"Asia/Riyadh",
            GTM_offset:""
        },
        {
            country_code:"IN",
            country_name:"India",
            time_zone:"Asia/Kolkata",
            GTM_offset:""
        },
        {
            country_code:"PK",
            country_name:"Pakistan",
            time_zone:"Asia/Karachi",
            GTM_offset:""
        },
    ],
    selectedTimeZone:localStorage.getItem("time_zone")?localStorage.getItem("time_zone"):"Asia/Riyadh",
};

const ManageTimeZoneReducer = (state = initialState,{type, data} = {}) => {
    switch (type) {
        case 'UPDATE_SELECTED_TIME_ZONE':
            return {
                ...state,
                selectedTimeZone: data
            };
        default:
            return state;
    }
};

export default ManageTimeZoneReducer;