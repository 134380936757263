const initialState = {
    categoryList: [],
    categoryTotal: 0,
    categorySearchKey: '',
    categoryDetail: '',
    productList: [],
    productTotal: 0,
    productSearchKey: '',
    productDetail: ''
};
const ManageProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_PRODUCT_CAT_SEARCHKEY':
            return {
                ...state,
                categorySearchKey: action.data
            };
        case 'UPDATE_PRODUCT_CAT_LIST':
            return {
                ...state,
                categoryTotal: action.data.total,
                categoryList: [...action.data.list]
            };
        case 'UPDATE_PRODUCT_CAT_PROFILE':
            return {
                ...state,
                categoryDetail: action.data
            };
        case 'UPDATE_PRODUCT_SEARCHKEY':
            return {
                ...state,
                productSearchKey: action.data
            };
        case 'UPDATE_PRODUCT_LIST':
            return {
                ...state,
                productTotal: action.data.total,
                productList: [...action.data.list]
            };
        case 'UPDATE_PRODUCT_PROFILE':
            return {
                ...state,
                productDetail: action.data
            };
        default:
            return state;
    }
};

export default ManageProductReducer;