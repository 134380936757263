export const permissionIdList = {
  DASHBOARD: "/dashboard",
  DESIGNER: {
    PROFILE: "/designer/profile",
    ORDER: "/designer/order",
    EARNING: "/designer/earning",
  },
  MANAGE_USERS: {
    ADMIN: {
      LIST: "/manage_users/admin",
      ADD: "/manage_users/admin/add",
      EDIT: "/manage_users/admin/edit",
      DELETE: "/manage_users/admin/delete",
      UPDATE_STATUS: "/manage_users/admin/update-status",
      OVERVIEW_TAB: "/manage_users/admin/profile/:userId/overview",
      UPDATE_PERMISSION: "/manage_users/admin/profile/:userId/permissions",
    },
    AREA_MANAGER: {
      LIST: "/manage_users/area-manager",
      ADD: "/manage_users/area-manager/add",
      EDIT: "/manage_users/area-manager/edit",
      DELETE: "/manage_users/area-manager/delete",
      UPDATE_STATUS: "/manage_users/area-manager/update-status",
      UPDATE_LEAD_RECEIVING: "/manage_users/area-manager/update-lead-receiving",
      VIEW_PROFILE_TAB: "/manage_users/area-manager/profile/:userId/profile",
      VIEW_DESIGNER_TAB: "/manage_users/area-manager/profile/:userId/designer",
      VIEW_FITTER_TAB: "/manage_users/area-manager/profile/:userId/fitter",
      VIEW_DELIVERY_AGENT:
        "/manage_users/area-manager/profile/:userId/delivery-agent",
      UPDATE_PERMISSION:
        "/manage_users/area-manager/profile/:userId/permissions",
      DESIGNER_WORK_PLATFORM_TOGGLE:
        "/manage_users/area-manager/profile/:userId/designer/workplatform-toggle",
    },
    DESIGNER: {
      LIST: "/manage_users/designer",
      ADD: "/manage_users/designer/add",
      EDIT: "/manage_users/designer/edit",
      DELETE: "/manage_users/designer/delete",
      ASSIGN: "/manage_users/designer/assign",
      UPDATE_STATUS: "/manage_users/designer/update-status",
      UPDATE_HOLD_STATUS: "/manage_users/designer/update-hold-status",
      ACCEPT_ONLINE_ORDER_TOGGLE:
        "/manage_users/designer/accept-online-order-toggle",
      OVERVIEW_TAB: "/manage_users/designer/profile/:userId/overview",
      VIEW_PROFILE_TAB: "/manage_users/designer/profile/:userId/profile",
      VIEW_ORDER_TAB: "/manage_users/designer/profile/:userId/order",
      VIEW_EARNING_TAB: "/manage_users/designer/profile/:userId/earnings",
      UPDATE_PERMISSION: "/manage_users/designer/profile/:userId/permissions",
    },
    FITTER: {
      LIST: "/manage_users/fitter",
      ADD: "/manage_users/fitter/add",
      EDIT: "/manage_users/fitter/edit",
      DELETE: "/manage_users/fitter/delete",
      UPDATE_STATUS: "/manage_users/fitter/update-status",
      OVERVIEW_TAB: "/manage_users/fitter/profile/:userId/overview",
      UPDATE_PERMISSION: "/manage_users/fitter/profile/:userId/permissions",
    },
    DELIVERY_AGENT: {
      LIST: "/manage_users/delivery-agent",
      ADD: "/manage_users/delivery-agent/add",
      EDIT: "/manage_users/delivery-agent/edit",
      DELETE: "/manage_users/delivery-agent/delete",
      UPDATE_STATUS: "/manage_users/delivery-agent/update-status",
      OVERVIEW_TAB: "/manage_users/delivery-agent/profile/:userId/overview",
      UPDATE_PERMISSION:
        "/manage_users/delivery-agent/profile/:userId/permissions",
    },
    OTHER_USERS: {
      LIST: "/manage_users/other-users",
      ADD: "/manage_users/other-users/add",
      EDIT: "/manage_users/other-users/edit",
      DELETE: "/manage_users/other-users/delete",
      UPDATE_STATUS: "/manage_users/other-users/update-status",
      OVERVIEW_TAB: "/manage_users/other-users/profile/:userId/overview",
      UPDATE_PERMISSION:
        "/manage_users/other-users/profile/:userId/permissions",
    },
    CUSTOMER_CARE: {
      LIST: "/manage_users/customer_care",
      ADD: "/manage_users/customer_care/add",
      EDIT: "/manage_users/customer_care/edit",
      DELETE: "/manage_users/customer_care/delete",
      UPDATE_STATUS: "/manage_users/customer_care/update-status",
      OVERVIEW_TAB: "/manage_users/customer_care/overview",
      UPDATE_PERMISSION:
        "/manage_users/customer-care/profile/:userId/permissions",
    },
    CMS_PERSON: {
      LIST: "/manage_users/cms_person",
      ADD: "/manage_users/cms_person/add",
      EDIT: "/manage_users/cms_person/edit",
      DELETE: "/manage_users/cms_person/delete",
      UPDATE_STATUS: "/manage_users/cms_person/update-status",
      OVERVIEW_TAB: "/manage_users/cms_person/profile/:userId/overview",
      UPDATE_PERMISSION: "/manage_users/cms_person/profile/:userId/permissions",
    },
    BRANCHES: {
      LIST: "/manage_users/branches",
      CREATE: "/manage_users/branches/create",
    },
    BRANDS: {
      LIST: "/manage_users/brands",
      CREATE: "/manage_users/brands/create",
      EDIT: "/manage_users/brands/edit",
      DELETE: "/manage_users/brands/delete",
    },
  },
  MANAGE_ORDERS: {
    ORDERS: {
      LIST: "/manage_orders/orders",
      DETAILS_PAGE: "/manage_orders/orders/profile/:orderId",
      PR_APPROVAL_BUTTON: "/manage_orders/orders/profile/:orderId/approve-pr",
      ASSIGN_DESIGNER: "/manage_orders/orders/profile/:orderId/assign-designer",
      CHANGE_DESIGNER: "/manage_orders/orders/profile/:orderId/change-designer",
    },
    OTHER_ORDERS: {
      LIST: "/manage_other_orders/otherorders",
      DETAILS_PAGE: "/manage_orders/otherorders/profile/:orderId",
      ASSIGN_DESIGNER:
        "/manage_orders/otherorders/profile/:orderId/assign-designer",
      CHANGE_DESIGNER:
        "/manage_orders/otherorders/profile/:orderId/change-designer",
    },
    CUSTOMERS: {
      LIST: "/manage_orders/customers",
      DETAILS_PAGE: "/manage_orders/customers/profile/:orderId",
      SEND_REFERRAL: "/manage_orders/customers/send-referral",
      CREATE_CUSTOMER: "/manage_orders/customers/add",
    },
    PENDING_MULTIPLE_ORDERS_REQUESTS:
      "/manage_orders/pending_multiple_orders_requests",
    UNASSIGNED_ORDERS: "/manage_orders/unassigned_orders",
    MANAGE_SERVICE_TICKETS: {
      LIST: "/manage_orders/manage_service_tickets",
      DETAILS_PAGE: "/manage_orders/manage_service_tickets/profile/:ticketId",
      CREATE_ORDER: "/manage_orders/manage_service_tickets/create-order",
    },
    CREATE_OTHER_ORDERS:
      "/manage_other_orders/otherorders/upload-offer/:orderId/:otherOrderId",
  },
  MANAGE_PRODUCTS: {
    KITCHEN: {
      CATEGORIES: {
        LIST: "/manage_products/kitchen/categories",
        EDIT: "/manage_products/kitchen/categories/edit",
        DELETE: "/manage_products/kitchen/categories/delete",
        UPDATE_STATUS: "/manage_products/kitchen/categories/update-status",
        UPDATE_PRIORITY: "/manage_products/kitchen/categories/update-priority",
      },
      SHAPES: {
        LIST: "/manage_products/kitchen/shapes",
        ADD: "/manage_products/kitchen/shapes/add",
        EDIT: "/manage_products/kitchen/shapes/edit",
        DELETE: "/manage_products/kitchen/shapes/delete",
        UPDATE_STATUS: "/manage_products/kitchen/shapes/update-status",
      },
      MODELS: {
        LIST: "/manage_products/kitchen/models",
        ADD: "/manage_products/kitchen/models/add",
        EDIT: "/manage_products/kitchen/models/edit",
        DELETE: "/manage_products/kitchen/models/delete",
        UPDATE_STATUS: "/manage_products/kitchen/models/update-status",
      },
      DIMENSIONS: {
        LIST: "/manage_products/kitchen/dimensions",
        ADD: "/manage_products/kitchen/dimensions/add",
        EDIT: "/manage_products/kitchen/dimensions/edit",
        DELETE: "/manage_products/kitchen/dimensions/delete",
        UPDATE_STATUS: "/manage_products/kitchen/dimensions/update-status",
      },
      KITCHEN: {
        LIST: "/manage_products/kitchen",
        ADD: "/manage_products/kitchen/add",
        EDIT: "/manage_products/kitchen/edit",
        DELETE: "/manage_products/kitchen/delete",
        UPDATE_STATUS: "/manage_products/kitchen/update-status",
      },
    },
    PRODUCTS: {
      APPLIANCES: {
        CATEGORY: {
          LIST: "/manage_products/appliances/Acategories",
          ADD: "/manage_products/appliances/Acategories/add",
          EDIT: "/manage_products/appliances/Acategories/edit",
          DELETE: "/manage_products/appliances/Acategories/delete",
          UPDATE_STATUS:
            "/manage_products/appliances/Acategories/update-status",
        },
        MANUFACTURER: {
          LIST: "/manage_products/appliances/manufacturer",
          ADD: "/manage_products/appliances/manufacturer/add",
          EDIT: "/manage_products/appliances/manufacturer/edit",
          DELETE: "/manage_products/appliances/manufacturer/delete",
          UPDATE_STATUS:
            "/manage_products/appliances/manufacturer/update-status",
        },
        GROUP: {
          LIST: "/manage_products/appliances/group",
          ADD: "/manage_products/appliances/group/add",
          EDIT: "/manage_products/appliances/group/edit",
          DELETE: "/manage_products/appliances/group/delete",
          UPDATE_STATUS: "/manage_products/appliances/group/update-status",
        },
        SUB_GROUP: {
          LIST: "/manage_products/appliances/sub-group",
          ADD: "/manage_products/appliances/sub-group/add",
          EDIT: "/manage_products/appliances/sub-group/edit",
          DELETE: "/manage_products/appliances/sub-group/delete",
          UPDATE_STATUS: "/manage_products/appliances/sub-group/update-status",
        },
        ITEM_TYPE: {
          LIST: "/manage_products/appliances/item-type",
          ADD: "/manage_products/appliances/item-type/add",
          EDIT: "/manage_products/appliances/item-type/edit",
          DELETE: "/manage_products/appliances/item-type/delete",
          UPDATE_STATUS: "/manage_products/appliances/item-type/update-status",
        },
        PRODUCTS: {
          LIST: "/manage_products/appliances/products",
          ADD: "/manage_products/appliances/products/add",
          EDIT: "/manage_products/appliances/products/edit",
          DELETE: "/manage_products/appliances/products/delete",
          UPDATE_STATUS: "/manage_products/appliances/products/update-status",
          UPLOAD_PRODUCT_IMAGE:
            "/manage_products/appliances/products/upload-product-image",
        },
      },
    },
  },
  MANAGE_HOMEPAGE: {
    IDEA_BEHIND_NIULI: {
      LIST: "/manage_homepage/idea-behind-niuli",
    },
    WHY_NIULI: {
      LIST: "/manage_homepage/why-niuli",
    },
    HOW_NIULI_WORKS: {
      LIST: "/manage_homepage/how-niuli-works",
    },
    HOW_NIULI_IS_BETTER: {
      LIST: "/manage_homepage/how-niuli-is-better",
    },
    FIND_OUT_WHAT_YOU_LIKE: {
      LIST: "/manage_homepage/find-out-what-you-like",
    },
  },
  MANAGE_PROMOTION: {
    BANNER: {
      LIST: "/manage_promotion/banner",
      ADD: "/manage_promotion/banner/add",
      EDIT: "/manage_promotion/banner/edit",
      DELETE: "/manage_promotion/banner/delete",
      UPDATE_STATUS: "/manage_promotion/banner/update-status",
    },
    COUPONS: {
      LIST: "/manage_promotion/coupons",
      ADD: "/manage_promotion/coupons/add",
      EDIT: "/manage_promotion/coupons/edit",
      DELETE: "/manage_promotion/coupons/delete",
    },
  },
  MANAGE_KEY_CONFIG: {
    KEYS: "/manage/keys",
  },
  MANAGE_REPORTS: {
    SALES: "/manage_reports/sales",
    ACTIVITY: "/manage_reports/activity",
    EMAIL_REPORTS: "/manage_reports/email-reports",
    SALES_REPORT: "/sales-reports",
    FOLLOW_UP_REPORT: "/manage_reports/followUp",
    SALES_ORDER_REPORT: "/manage_reports/salesOrder",
    APPLIANCES_ORDERS_REPORT: "/manage_reports/appliances-orders",
    REMINDER_REPORT: "/manage_reports/reminderReport",
    SALES_REPORT_MTD_YTD: "/manage_reports/sales-report-mtd-ytd",
    SALES_REPORT_SUMMARY: "/manage_reports/sales-report-summary",
    KITCHEN_STOCK_AGING: "/manage_reports/kitchen-stock-aging",
    SUMMARY_REPORT: "/manage_reports/summary-report",
  },
  MANAGE_SALES_TARGET: {
    LIST: "/manage_target/sales",
    ADD: "/manage_target/sales/add",
    EDIT: "/manage_target/sales/edit/:targetId",
    DELETE: "/manage_target/sales/delete",
    COMPLETED_TARGET: "/manage_target/sales/completed-target",
    BULK_UPLOAD: "/manage_target/sales/bulk-upload",
  },
  MANAGE_REMINDER: {
    MANUAL_REMINDER: {
      LIST: "/manage_reminder/reminder",
    },
    AUTOMATED_REMINDER: {
      LIST: "/manage_reminder/automated",
    },
    PENDING_REMINDER: {
      LIST: "/manage_reminder/pending",
    },
  },
  MANAGE_CAMPAIGN: {
    SOCIAL_DASHBOARD: "/leads/social-dashboard",
    SOCIAL_MEDIA_CAMPAIGN: {
      LIST: "/leads/social",
      DETAILS_PAGE: "/leads/social/details/:id",
    },
    MANAGE_LEADS: {
      LIST: "/leads",
      DETAILS_PAGE: "/leads/details/:id",
    },
  },
  MANAGE_SAP: {
    SAP_SYNC_ISSUES: {
      LIST: "/sapSync/list",
      REFETCH: "/sapSync/refetch",
      RESYNC: "/sapSync/resync",
    },
    POSTING_PERIOD: {
      LIST: "/sapSync/posting-period",
      ADD: "/sapSync/posting-period/add",
      EDIT: "/sapSync/posting-period/update",
      DELETE: "/sapSync/posting-period/delete",
    },
  },
  MANAGE_AUTHORIZATION: {
    USER_TYPE: {
      LIST: "/authorization/userGroup/list",
      ADD: "/authorization/userGroup/add",
    },
    PERMISSIONS: {
      LIST: "/authorization/permissions",
      UPDATE_PERMISSION: "/authorization/permissions/update",
      UPDATE_PERMISSION_TEMPLATE:
        "/authorization/permissions/update-permission-template",
    },
  },
};
