import React, { useState } from "react";
import { Modal } from "reactstrap";

const VatWarningModal = ({
  vatWarningModal,
  setVatWarningModal,
  generateInvoicePayload,
  setGenerateInvoicePayload,
  ClickgenerateInvoice,
}) => {
  const [confirmDownload, setConfirmDownload] = useState(null);

  const modalHandler = () => {
    setGenerateInvoicePayload({});
    setVatWarningModal(false);
  };

  const confirmGenerateInvoice = () => {
    if (confirmDownload) {
      ClickgenerateInvoice(
        generateInvoicePayload.orderStage,
        generateInvoicePayload.id,
        generateInvoicePayload.taxAmount,
        generateInvoicePayload.paymentAmount
      );
      setVatWarningModal(false);
    }
    setConfirmDownload(false);
  };

  return (
    <Modal
      className="set-default-address-model"
      footer={null}
      isOpen={vatWarningModal}
      toggle={modalHandler}
      centered
    >
      <h5 className="text-dark mb-2 font-weight-lighter">
        Are you sure you want to Generate Invoice without VAT ?{" "}
      </h5>
      <div className="d-flex justify-content-start px-2">
        <input
          className="confirmCheckbox mr-1 mt-0"
          id="confirmCheckBox"
          type="checkbox"
          value={confirmDownload}
          onChange={(e) => setConfirmDownload(e.target.checked)}
        />
        <label htmlFor="confirmCheckBox" className="h5" check>
          I have confirmed that I am Generating Invoice without VAT.
        </label>
      </div>
      {confirmDownload === false && (
        <p className="text-danger mb-0 ml-4  pb-2">
          Please click on the Checkbox to accept
        </p>
      )}
      <div className="d-flex justify-content-center">
        <button className="notConfirmDelete-button ms-0" onClick={modalHandler}>
          Cancel
        </button>
        <button
          className="confirmDelete-button"
          onClick={confirmGenerateInvoice}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};

export default VatWarningModal;
