// import { format, formatISO } from 'date-fns'
import moment from "moment-timezone";
// import { string } from 'prop-types';

export const isDate = (value) => {
  const date = new Date(value);

  return !!value && date.toString() !== "Invalid Date";
};

export const getISO = (date, onlyDate = true) => {
  if (!date) {
    return null;
  }
  const options = onlyDate ? { representation: "date" } : {};
  return options;
  // return formatISO(new Date(date), options)
};

export const formatDate = (date, separator = ".") => {
  if (date) {
    const dateObj = new Date(date);
    return dateObj;
    // return format(dateObj, `MM${separator}dd${separator}yyyy`)
  }
  return "";
};

export const formatDateWithFormat = (
  date,
  formatType,
  timeZone = "Asia/Riyadh"
) => {
  if (date) {
    const dateObj = new Date(date);
    return moment(dateObj).tz(timeZone).format(formatType);
    // return format(dateObj, formatType)
  }
  return "N/A";
};
export const formatStringToUTC = (date) => {
  if (typeof date !== "string") {
    return new Date().toISOString();
  }
  return date.replace(" ", "T") + "+00:00";
};

export const getCurrentDate = () => {
  return moment().format("YYYY-MM-DD");
};

export const formatHour = (date) => {
  if (date) {
    const dateObj = new Date(date);
    return dateObj;
    // return format(dateObj, 'hh:mm aaa')
  }
  return "";
};

export const removeTrailingSlash = (url) => {
  const fixedPath = url.endsWith("/")
    ? removeTrailingSlash(url.substring(0, url.length - 1))
    : url;
  return fixedPath;
};

export const countAbbreviation = (number, decPlaces) => {
  // var orig = number;
  // var dec = decPlaces;
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ["K", "M", "B", "T"];

  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }
  return number;
};

export const getAge = (date) => {
  return moment().diff(date, "years", false);
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const getTitle = (type) => {
  switch (type) {
    case "fitter":
      return {
        title: "Fitters",
        btnTitle: "Add New Fitter",
        editTitle: "Edit Fitter",
        sTitle: "Fitter",
        amTitle: "Fitters",
        amBtnTitle: "Add Fitter",
      };
    case "designer":
      return {
        title: "Designers",
        btnTitle: "Add New Designer",
        editTitle: "Edit Designer",
        sTitle: "Designer",
        amTitle: "Designers",
        amBtnTitle: "Add Designer",
      };
    //COMMENTED AREA MANAGER
    // case 'area-manager':
    //   return {
    //     title: "Manage Area Manager",
    //     btnTitle: "Add New Area Manager",
    //     editTitle: "Edit Area Manager",
    //     sTitle: 'Area Manager',
    //     amTitle: 'Area Managers',
    //     amBtnTitle: "Add Area Manager",
    //   }
    case "area-manager":
      return {
        title: "Coaches",
        btnTitle: "Add New Coach",
        editTitle: "Edit Coach",
        sTitle: "Coach",
        amTitle: "Coaches",
        amBtnTitle: "Add Coach",
      };
    case "delivery-agent":
      return {
        title: "Delivery Agents",
        btnTitle: "Add New Delivery Agent",
        editTitle: "Edit Delivery Agent",
        sTitle: "Delivery Agent",
        amTitle: "Delivery Agents",
        amBtnTitle: "Add Delivery Agent",
      };
    case "customer_care":
      return {
        title: "Customer Care  Persons",
        btnTitle: "Add New Customer Care",
        editTitle: "Edit Customer Care",
        sTitle: "Customer Care",
        amTitle: "Customer Cares",
        amBtnTitle: "Add Customer Care",
      };
    //NEW
    case "cms_person":
      return {
        title: "Cms Persons",
        btnTitle: "Add New Cms Person",
        editTitle: "Edit Cms Person",
        sTitle: "Cms Person",
        amTitle: "Cms Persons",
        amBtnTitle: "Add Cms Person",
      };
    case "admin":
      return {
        title: "Admins",
        btnTitle: "Add New Admin",
        editTitle: "Edit Admin",
        sTitle: "Admin",
        amTitle: "Admins",
        amBtnTitle: "Add Admin",
      };
    case "other-users":
      return {
        title: "Other Users",
        btnTitle: "Add New User",
        editTitle: "Edit User",
        sTitle: "Users",
        amTitle: "Users",
        amBtnTitle: "Add Users",
      };
    default:
      return {
        title: "No title",
        btnTitle: "No title",
        editTitle: "No title",
        sTitle: "No title",
        amTitle: "No title",
        amBtnTitle: "No title",
      };
  }
};

export const timestampForFileName = () => {
  return moment().format("YYYYMMDDHHmmss");
};

export const toUpperCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getOrderStatusData = (status, statusArr) => {
  let statusObj =
    typeof statusArr !== "undefined" && statusArr && statusArr.length
      ? statusArr.find((o) => o.checklist_stage === status)
      : "";
  return statusObj;
};

export const getCurrency = (value) => {
  if (!value) {
    return 0;
  }
  if (!+value) {
    return 0;
  }
  return +value.toFixed(2);
};

export const formatUnderScoreValues = (value) => {
  if (!value) {
    return "";
  }
  let stringValue = `${value}`;
  let stringArr = stringValue.split("_");
  let formatedStrin = "";
  stringArr.forEach(
    (val) =>
      (formatedStrin =
        formatedStrin + " " + val.charAt(0).toUpperCase() + val.slice(1))
  );

  return formatedStrin;
};

export function formatSARCurrency(number) {
  const parts = Number(number).toFixed(2).toString().split(".");
  const integerPart = parts[0];
  const decimalPart = parts[1] || "00";
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (decimalPart === "00") {
    return `${formattedInteger}`;
  } else {
    return `${formattedInteger}.${decimalPart}`;
  }
}

export const formatOrderType = (orderType = "") => {
  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  let formatedOrderType = "";

  orderType.split("_").forEach((type) => {
    if (formatedOrderType === "") {
      formatedOrderType = capitalizeFirstLetter(type);
    } else {
      formatedOrderType += ` ${capitalizeFirstLetter(type)}`;
    }
  });
  return formatedOrderType;
};

// format userType to match it with permissions object
export const formartUserType = (userType) => {
  if (typeof userType !== "string") {
    return "";
  }
  return userType.toUpperCase().replace(/-/g, "_");
};
