import React, { useEffect, useState } from "react"
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Media,
    Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
import reminderBell from '../../../../assets/icons/svg/reminder-bell.svg'
import { Link } from "react-router-dom"
import { getNotification, updateNotificationReadAllStatusApi, updateNotificationReadStatusApi } from 'services/other';
import { formatDateWithFormat } from 'utility/format';
import { getToken, sessionCheck } from 'utility/store';
import './reminder-dropdown-component.scss';

const _getToken = getToken();
const ReminderDropdownComponent = () => {
    const [notification, setNotification] = useState([]);
    const [count, setCount] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const handleReminders = () => {
        localStorage.setItem("today_Reminder", true)
    }
    const setCreateReminder = () => {
        localStorage.setItem('createRem', true)
        localStorage.setItem('clearRem', true)
    }

    return (
        <>
        <UncontrolledDropdown
            tag="li"
            className="dropdown-reminder nav-item"
            isOpen={dropdownOpen} toggle={toggle}
        >
            <DropdownToggle
                tag="a"
                className="nav-link nav-link-label">
                <img src={reminderBell} style={{width:'21px'}}/>
                <Icon.ChevronDown size={21} />
            </DropdownToggle>
            <DropdownMenu tag="ul" right className="dropdown-reminder-menu-media">
                
                <li className="dropdown-menu-footer">
                    <DropdownItem tag="a" className="p-1 text-center">
                        {/* <Link> */}
                        <Link to='/manage_reminder/reminder'
                            onClick={() => setCreateReminder()}>
                            <span className="align-middle">Create Customer Reminder</span>
                        </Link>
                    </DropdownItem>
                </li>

                <li className="dropdown-menu-footer">
                    <DropdownItem tag="a" className="p-1 text-center">
                        <Link to='/manage_reminder/reminder'>
                            <span className="align-middle">Manage Reminder</span>
                        </Link>
                    </DropdownItem>
                </li>

                <li className="dropdown-menu-footer">
                    <DropdownItem tag="a" className="p-1 text-center">
                            <Link onClick={()=>handleReminders()}>
                            <span className="align-middle">Today's Reminder</span>
                        </Link>
                    </DropdownItem>
                </li>

                <li className="dropdown-menu-footer">
                    <DropdownItem tag="a" className="p-1 text-center">
                        <Link to='/manage_reminder/pending'>
                            <span className="align-middle">Pending Action Reminder</span>
                        </Link>
                    </DropdownItem>
                </li>
            </DropdownMenu>
        </UncontrolledDropdown>

    </>
    )
}
export default ReminderDropdownComponent;
