const initialState = {
  assignModelToggle: false,
  addCategoryModelToggle: false,
  orderAssignModelToggle: false
};
const ModelStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ASSIGN_USER_MODEL_TOGGLE':
      return { ...state, assignModelToggle: action.data };
    case 'ADD_CATEGORY_MODEL_TOGGLE':
      return { ...state, addCategoryModelToggle: action.data };
    case 'ORDER_ASSIGN_MODEL_TOGGLE':
      return { ...state, orderAssignModelToggle: action.data };
    default:
      return state;
  }
};

export default ModelStateReducer;