const initialState = {
  listLoading: false,
  profileLoading: false,
  assignUserListLoading: false
};
const LoadingStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LIST_LOADING_TRUE':
      return { ...state, listLoading: true };
    case 'LIST_LOADING_FALSE':
      return { ...state, listLoading: false };
    case 'PROFILE_LOADING_TRUE':
      return { ...state, profileLoading: true };
    case 'PROFILE_LOADING_FALSE':
      return { ...state, profileLoading: false };
    case 'ASSIGN_USER_LIST_LOADING':
      return { ...state, assignUserListLoading: action.data };
    default:
      return state;
  }
};

export default LoadingStateReducer;