// import { history } from "../../history";
import { toast } from "react-toastify";
// import Cookies from 'js-cookie'
// import { path } from 'ramda'

// export const setUser = ({ player, user }) => {
//   if (!user) {
//     console.warn(`user cannot be ${user}`)
//   }

//   localStorage.setItem('player', JSON.stringify(player))
//   localStorage.setItem('user', JSON.stringify(user))
// }

// export const setToken = token => {
//   Cookies.set('token', JSON.stringify(token))
// }

// export const getToken = () => {
//   const token = Cookies.get('token')

//   if (token) {
//     return JSON.parse(token)
//   }

//   return null
// }

// export const getUser = () => JSON.parse(localStorage.getItem('user'))
// export const getPlayer = () => JSON.parse(localStorage.getItem('player'))

// export const getPlayerTags = () => {
//   const tags = path(['tags'], getPlayer())

//   return tags
// } 

// export const updatePlayer = object => {
//   const player = getPlayer()

//   if (!player) {
//     console.warn('tried to update unexisting user')
//   }

//   const keys = Object.keys(player)

//   keys.forEach(k => {
//     player[k] = object[k]
//   })

//   localStorage.setItem('player', JSON.stringify(player))
// }

export const setUser = (data) => {
  localStorage.setItem('email', data.email)
  localStorage.setItem('_id', data._id)
  localStorage.setItem('name', data.name)
  localStorage.setItem('userProfilePic', data.profile_image)
  localStorage.setItem('admin_type', data.admin_type)
  localStorage.setItem('token', data.token)
  localStorage.setItem('city', data.city)
  if (data.admin_type === "customer_care") {
    window.location = `${window.location.origin}/manage_orders/orders?page=1&size=10`;
  }else if(data.admin_type === "cms_person"){
    window.location = `${window.location.origin}/manage_products/kitchen?page=1&size=10`;
  }else if(data.isDraftUpload){
    window.location = data.url;
  }
   else {
    window.location = `${window.location.origin}/dashboard`;
  }
}


export const clearUser = () => {
  localStorage.removeItem('email')
  localStorage.removeItem('_id')
  localStorage.removeItem('name')
  localStorage.removeItem('userProfilePic')
  localStorage.removeItem('admin_type')
  localStorage.removeItem('token')
  localStorage.removeItem('cityList')
  localStorage.removeItem('coachList')
  localStorage.removeItem('remindToList')
  localStorage.removeItem('actionList')
  localStorage.removeItem('actionMainList')
  localStorage.removeItem('branch_id')
  localStorage.removeItem('work_platform')
  localStorage.removeItem('permissionList')
  window.location = `${window.location.origin}`;
}

export const sessionCheck = (data) => {
  if (data.status_code && data.status_code == 408) {
    localStorage.removeItem('email')
    localStorage.removeItem('_id')
    localStorage.removeItem('name')
    localStorage.removeItem('userProfilePic')
    localStorage.removeItem('admin_type')
    localStorage.removeItem('token')
    localStorage.removeItem('branch_id')
    localStorage.removeItem('work_platform')
    localStorage.removeItem('permissionList')
    window.location = `${window.location.origin}/misc/session-expired`;
  }
}

export const getToken = () => {
  const token = localStorage.getItem('token');
  const admin_type = localStorage.getItem('admin_type');
  const _id = localStorage.getItem('_id')
  const userProfilePic = localStorage.getItem('userProfilePic')
  if (token) {
    return { token, admin_type, _id, userProfilePic };
  }
  return null
}