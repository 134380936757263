

export const accountIds={
    onder:"60ae6831e53074000797be27",
    asim:"633d3c9b321f0b0009c62a4f",
    adreesFinanceTeam:"66d6e544e0adc30008457bcd",
    haniImam:"652d1d369795190008416ff3",
    saadAkbar:"620df8bd0ed8e8000877580e",
    dawood:"60a90721f162e7000815f00c",
    codewave:"60a908abf162e7000815f00e",
    naveedAbdullah:"63749448f734c70008af0cc4",
    junaidAsad:"63738111010b7f0009dabce9",
    onderQA:"60e6965656446600086f6610",
    shehzad:"60d0ac1ad92d86000837aa2d",
    zukwan:"66c325192b6f680008535616",
    houssam:"66c47b07a331310008fb5ef1",
    azhar:"64059aecc53a770008abd29d"
}