import axios from "axios";
import swal from "sweetalert";
import config from "../../configs/properties";

export const GenerateInvoiceApi = (data) => {
  return async () => {
    try {
      const assetrequest = await axios.post(
        config.base_url + "admin/generateTaxInvoice",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(assetrequest, "assetrequest");
      return assetrequest;
    } catch (e) {}
  };
};

export const DeleteInvoiceApi = (data) => {
  return async () => {
    try {
      const assetrequest = await axios.post(
        config.base_url + "admin/deleteTaxInvoice",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(assetrequest, "assetrequest");
      return assetrequest;
    } catch (e) {}
  };
};
