import React from "react";
import './loader-component.scss';
import loader from "../../../../assets/loader/loader.gif"

const NiuliLoader = () => {

    return (
        <div className="loader_div">
            <img src={loader} />
        </div>
    )
}
export default NiuliLoader;
