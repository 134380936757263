const initialState = {
    list: [],
    total:0,
    designerlist: [],
    download_url: null,
    designerdownload_url:null,
    currency:"",
    designercurrency:""
  };
  const ManageReportReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_REPORT_ACTIVITIES':
        console.log(action)
        return {
          ...state,
          download_url: action.data.download_url,
          currency:action.data.currency,
          list: [...action.data.list]
        };
      case 'UPDATE_REPORT_DESIGNERS':
        return {
          ...state,
          designerdownload_url: action.data.designerdownload_url,
          designercurrency:action.data.designercurrency,
          designerlist: [...action.data.designerlist],
          total: action.data.total,
        };
      case 'UPDATE_REPORT_SALESORDER':
        return {
          ...state,
          list: [...action.data.list],
          total: action.data.total
        }
      case 'UPDATE_REPORT_REMINDER':
        return {
          ...state,
          list: [...action.data.list],
          total: action.data.total
        }
      
      default:
        return state;
    }
  };
  
  export default ManageReportReducer;