const initialState = {
    list: [],
    total: 0,
    searchKey: '',
    detail: ''
};
const ManageHomePageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_HOMEPAGE_SEARCHKEY':
            return {
                ...state,
                searchKey: action.data
            };
        case 'UPDATE_HOMEPAGE_LIST':
            return {
                ...state,
                total: action.data.total,
                list: [...action.data.list]
            };
        case 'UPDATE_HOMEPAGE_PROFILE':
            return {
                ...state,
                detail: action.data
            };
        default:
            return state;
    }
};

export default ManageHomePageReducer;