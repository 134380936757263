const addUserList = (list, total) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_USER_LIST', data: { list, total } });
  };
};

const updateSearchKey = (key) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_USER_SEARCHKEY', data: key });
  };
};

const updateUserProfileData = (pdata) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_USER_PROFILE', data: pdata });
  };
};

const updateUserProfileMasterData = (pdata) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_USER_MASTER_PROFILE', data: pdata });
  };
};

const updateActiveDesignerList = (list) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_ACTIVE_DESIGNER_LIST', data: list });
  };
};

const updateActiveFitterList = (list) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_ACTIVE_FITTER_LIST', data: list });
  };
};

const updateActiveDeliveryPersonList = (list) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_ACTIVE_DELIVERY_PERSON_LIST', data: list });
  };
};

const updateActiveAreaManagerList = (list) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_ACTIVE_AREA_MANAGER_LIST', data: list });
  };
};

const updateUserId = (obj) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_USER_ID', data: obj });
  };
};

export {
  addUserList, updateSearchKey,
  updateUserProfileData, updateActiveDesignerList,
  updateActiveFitterList, updateActiveDeliveryPersonList,
  updateActiveAreaManagerList, updateUserProfileMasterData,
  updateUserId
};