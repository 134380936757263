import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Collapse,
  Button,
  Input,
} from "reactstrap";
import { ChevronDown } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import swal from "sweetalert";
import { ClipLoader } from "react-spinners";
import { formatDateWithFormat } from "utility/format";
import {
  GenerateInvoiceApi,
  DeleteInvoiceApi,
} from "../../../../services/generateinvoice";
import "./payment-collapsible-component.scss";
import moment from "moment";
import { getToken } from "utility/store";
import { accountIds } from "../../../../utility/store/accountIds";
import VatWarningModal from "./vatWarningModal/VatWarningModal";

const _getToken = getToken();
const adminType =
  _getToken && _getToken?.admin_type ? _getToken?.admin_type : "super";
const admin_id = _getToken && _getToken?._id ? _getToken?._id : "";

let PaymentStageId = "";
let paymentStagefor = "";
let paymentPercentage = "";

const PaymentCollapsibleComponent = ({
  type,
  transaction_history_data,
  transaction_list,
  detail,
  order_id,
  draft,
  order,
  country_configuration,
  paymentstage,
  changedPaymentAmount,
  setChangedPaymentAmount,
  otherOrder,
}) => {
  const { selectedTimeZone } = useSelector((store) => store.timeZone);
  const [status, setStatus] = useState("Opened");
  const [collapse, setCollapse] = useState(false);
  // const [changedPaymentAmount, setChangedPaymentAmount] = useState('');

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const [vatWarningModal, setVatWarningModal] = useState(false);
  const [generateInvoicePayload, setGenerateInvoicePayload] = useState({});

  // useEffect(() => {
  //   if (type === "offline_payment") {
  //     const paymentAmount = (parseFloat(paymentstage.payment_amount) + parseFloat(paymentstage.tax_amount)).toFixed(2);
  //     setChangedPaymentAmount(paymentAmount);
  //   }
  // }, [paymentstage]);
  const generateInvoiceHandler = (
    orderStage,
    id,
    taxAmount,
    paymentAmount,
    transactionVatNo
  ) => {
    if (
      !detail?.billing_vat_number ||
      detail.billing_vat_number === "" ||
      !transactionVatNo ||
      transactionVatNo === ""
    ) {
      setGenerateInvoicePayload({
        orderStage,
        id,
        taxAmount,
        paymentAmount,
      });
      return setVatWarningModal(true);
    }
    ClickgenerateInvoice(orderStage, id, taxAmount, paymentAmount);
  };
  const ClickgenerateInvoice = async (
    value,
    transactionId,
    taxamount,
    paymentamount
  ) => {
    setLoader(true);
    let payment_amount = paymentamount ? Number(paymentamount) : 0;
    let tax_amount = taxamount ? Number(taxamount) : 0;
    const rowLen = detail != undefined ? detail.order_payment_stages.length : 0;
    let orderpaymentstageId =
      rowLen > 0
        ? detail.order_payment_stages.map((allpaymentid, i) => {
            return allpaymentid._id;
          })
        : "";
    let orderpaymentstagefor =
      rowLen > 0
        ? detail.order_payment_stages.map((allpayment, i) => {
            return allpayment.payment_for;
          })
        : "";
    let orderpaymentpercentage =
      rowLen > 0
        ? detail.order_payment_stages.map((allpayment, i) => {
            return allpayment.payment_percentage;
          })
        : "";
    console.log(orderpaymentstagefor);
    console.log(orderpaymentstageId);
    if (orderpaymentstagefor.length == 3) {
      PaymentStageId =
        orderpaymentstagefor[0] === value
          ? orderpaymentstageId[0]
          : orderpaymentstagefor[orderpaymentstagefor.length - 2] === value
          ? orderpaymentstageId[orderpaymentstageId.length - 2]
          : orderpaymentstagefor[orderpaymentstagefor.length - 1] === value
          ? orderpaymentstageId[orderpaymentstageId.length - 1]
          : "";

      paymentStagefor =
        orderpaymentstagefor[0] === value
          ? orderpaymentstagefor[0]
          : orderpaymentstagefor[orderpaymentstagefor.length - 2] === value
          ? orderpaymentstagefor[orderpaymentstagefor.length - 2]
          : orderpaymentstagefor[orderpaymentstagefor.length - 1] === value
          ? orderpaymentstagefor[orderpaymentstagefor.length - 1]
          : "";

      paymentPercentage =
        orderpaymentstagefor[0] === value
          ? orderpaymentpercentage[0]
          : orderpaymentstagefor[orderpaymentstagefor.length - 2] === value
          ? orderpaymentpercentage[orderpaymentpercentage.length - 2]
          : orderpaymentstagefor[orderpaymentstagefor.length - 1] === value
          ? orderpaymentpercentage[orderpaymentpercentage.length - 1]
          : "";
    } else if (orderpaymentstagefor.length == 2) {
      PaymentStageId =
        orderpaymentstagefor[0] === value
          ? orderpaymentstageId[0]
          : orderpaymentstagefor[orderpaymentstagefor.length - 1] === value
          ? orderpaymentstageId[orderpaymentstageId.length - 1]
          : "";

      paymentStagefor =
        orderpaymentstagefor[0] === value
          ? orderpaymentstagefor[0]
          : orderpaymentstagefor[orderpaymentstagefor.length - 1] === value
          ? orderpaymentstagefor[orderpaymentstagefor.length - 1]
          : "";

      paymentPercentage =
        orderpaymentstagefor[0] === value
          ? orderpaymentpercentage[0]
          : orderpaymentstagefor[orderpaymentstagefor.length - 1] === value
          ? orderpaymentpercentage[orderpaymentpercentage.length - 1]
          : "";
    }
    let invoiveValues = {
      id: detail._id,
      payment_stage_id: PaymentStageId,
      payment_stage: paymentStagefor,
      payment_percentage: paymentPercentage,
      order_total: parseFloat(payment_amount + tax_amount).toFixed(2),
      order_total_without_vat: parseFloat(payment_amount).toFixed(2),
      vat_amount: parseFloat(tax_amount).toFixed(2),
      transaction_id: transactionId,
      token: localStorage.getItem("token"),
    };
    console.log(invoiveValues);
    const editApi = await dispatch(GenerateInvoiceApi(invoiveValues));
    console.log(editApi, "editApi");
    if (editApi.data.status_code === 200) {
      setLoader(false);
      swal("Invoice generated Successfully", "", "success").then((value) => {
        window.location.reload();
      });
    }
  };

  const deleteInvoice = async (transaction_id, order_id) => {
    setLoader(true);
    let invoiveValues = {
      transaction_id,
      order_id,
      token: localStorage.getItem("token"),
    };
    console.log("abc", invoiveValues);
    const editApi = await dispatch(DeleteInvoiceApi(invoiveValues));
    console.log(editApi, "editApi", "abc");
    if (editApi.data.status_code === 200) {
      setLoader(false);
      swal("Invoice deleted Successfully", "", "success").then((value) => {
        window.location.reload();
      });
    } else if (editApi.data.status_code === 405) {
      setLoader(false);
      swal("Operation is not allowed", "", "error").then((value) => {
        window.location.reload();
      });
    } else {
      setLoader(false);
      swal("Somtiong want wrong", "", "error").then((value) => {
        window.location.reload();
      });
    }
  };

  console.log(order, "Asdasdsada");
  const ItemTotalBody = ({ draft, order, country_configuration }) => {
    let _quotation_amount_without_vat =
      otherOrder == "otherOrder"
        ? order && order.total_order_line_amount
        : draft.quotation_amount_without_vat
        ? draft.quotation_amount_without_vat
        : 0;
    if (order.discount_applied) {
      _quotation_amount_without_vat =
        _quotation_amount_without_vat +
        Number(draft.discount_amount ? draft.discount_amount : 0);
    }
    return (
      <>
        <Row>
          <Col>
            <p className="pay-p-font">Items Total</p>
          </Col>
          <Col>
            <p className="pay-p-font pay-f-r">
              {country_configuration.currency}{" "}
              {parseFloat(_quotation_amount_without_vat).toFixed(2)}
            </p>
          </Col>
        </Row>
        {order.order_checklist_status_number>=15 && (order.discount_applied ||
        order.total_order_discount_percentage ||
        order.cus_referral_code_applied === true) ? (
          <Row>
            <Col>
              <p className="pay-s-font">
                Discount applied (
                {otherOrder == "otherOrder" ? (
                  order && order.total_order_discount_percentage ? (
                    `${order && order.total_order_discount_percentage} %`
                  ) : (
                    ""
                  )
                ) : (
                  <>
                    {order.coupon_code}{" "}
                    {(order.discount_applied ||
                      order.total_order_discount_percentage) &&
                    order.cus_referral_code_applied === true
                      ? " + "
                      : ""}
                    {order.cus_referral_code_applied === true
                      ? "5% Referral Discount"
                      : ""}
                  </>
                )}
                )
              </p>
            </Col>
            <Col>
              <p className="pay-s-font pay-f-r pay-g-color">
                -{country_configuration.currency}{" "}
                {otherOrder == "otherOrder"
                  ? order && order.total_order_discount_amount
                    ? parseFloat(
                        order && order.total_order_discount_amount
                      ).toFixed(2)
                    : ""
                  : draft.discount_amount
                  ? parseFloat(draft.discount_amount).toFixed(2)
                  : ""}
              </p>
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Row>
          <Col>
            <p className="pay-p-font">
              Tax{" "}
              {otherOrder == "otherOrder"
                ? ""
                : draft.vat
                ? ` (${draft.vat}%)`
                : ""}
            </p>
          </Col>
          <Col>
            <p className="pay-p-font pay-f-r">
              {country_configuration.currency}{" "}
              {otherOrder == "otherOrder"
                ? order && order.total_order_tax
                  ? parseFloat(order && order.total_order_tax).toFixed(2)
                  : 0
                : draft.vat_amount
                ? parseFloat(draft.vat_amount).toFixed(2)
                : 0}
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p className="pay-p-font">Total Amount</p>
          </Col>
          <Col>
            <p className="pay-p-font pay-f-r">
              {country_configuration.currency}{" "}
              {otherOrder == "otherOrder"
                ? order && order.total_order_amount_after_tax
                  ? parseFloat(
                      order && order.total_order_amount_after_tax
                    ).toFixed(2)
                  : 0
                : draft.quotation_amount
                ? parseFloat(draft.quotation_amount).toFixed(2)
                : 0}
            </p>
          </Col>
        </Row>
      </>
    );
  };

  const ItemTotalHeader = ({
    order_id,
    draft,
    country_configuration,
    order,
    otherOrder,
  }) => (
    <>
      <Row>
        <Col>
          <p className="pay-s-font pay-s-color">Order ID</p>
          <p className="pay-p-font">{order_id}</p>
        </Col>
        <Col>
          <p className="pay-p-font pay-f-r pay-mt15">
            {country_configuration.currency}{" "}
            {otherOrder == "otherOrder"
              ? order && order.total_order_amount_after_tax
                ? parseFloat(
                    order && order.total_order_amount_after_tax
                  ).toFixed(2)
                : 0
              : draft.quotation_amount
              ? parseFloat(draft.quotation_amount).toFixed(2)
              : 0}
          </p>
        </Col>
      </Row>
    </>
  );

  const TransactionBody = ({
    transaction_history_data,
    country_configuration,
  }) => {
    let payment_amount = transaction_history_data.payment_amount
      ? Number(transaction_history_data.payment_amount)
      : 0;
    let tax_amount = transaction_history_data.tax_amount
      ? Number(transaction_history_data.tax_amount)
      : 0;
    return (
      <>
        <Row>
          <Col>
            <p className="pay-p-font">Amount</p>
          </Col>
          <Col>
            <p className="pay-p-font pay-f-r">
              {country_configuration.currency}{" "}
              {parseFloat(payment_amount).toFixed(2)}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="pay-p-font">Tax</p>
          </Col>
          <Col>
            <p className="pay-p-font pay-f-r">
              {country_configuration.currency}{" "}
              {parseFloat(tax_amount).toFixed(2)}
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p className="pay-p-font">
              {transaction_history_data.payment_type === "CUSTOMER_REFUND"
                ? "Amount Refunded"
                : "Amount Paid"}
            </p>
          </Col>
          <Col>
            <p className="pay-p-font pay-f-r">
              {country_configuration.currency}{" "}
              {parseFloat(payment_amount + tax_amount).toFixed(2)}
            </p>
          </Col>
        </Row>
      </>
    );
  };

  const offlinePaymentAmountValueChange = (e) => {
    let amountpaid = 0;
    let totalOrderPrice = 0;
    if (transaction_list && transaction_list.length > 0) {
      for (let i = 0; i < transaction_list.length; i++) {
        let amount = parseFloat(transaction_list[i]["payment_amount"]);
        let tax = parseFloat(transaction_list[i]["tax_amount"]);
        amountpaid = parseFloat(amountpaid) + (amount + tax);
      }
    }
    for (let i = 0; i < detail.order_payment_stages.length; i++) {
      let tempTax =
        (detail.order_payment_stages[i]["payment_amount"] *
          paymentstage.tax_percentage) /
        100;
      totalOrderPrice =
        totalOrderPrice +
        detail.order_payment_stages[i]["payment_amount"] +
        tempTax;
    }
    totalOrderPrice = totalOrderPrice - amountpaid;

    const amount = e.target.value;
    if (
      !isNaN(amount) &&
      parseFloat(amount).toFixed(2) <= +totalOrderPrice.toFixed(2)
    ) {
      setChangedPaymentAmount(e.target.value);
    }
  };

  // const OfflinepaymentHeader = ({ paymentstage, country_configuration }) => {
  //   console.log(paymentstage);
  //   console.log(country_configuration);
  //   return (
  //     <>
  //       <Row>
  //         <Col>
  //           <p className="pay-s-font pay-s-color">Order ID</p>
  //           <p className="pay-p-font">{paymentstage.order_id}</p>
  //         </Col>
  //         <Col>
  //           <p className="pay-p-font pay-f-r pay-mt15">
  //             {country_configuration.currency}{" "}
  //             <Input type="text" onChange={offlinePaymentAmountValueChange} value={changedPaymentAmount} className="font_fix" />

  //             {(
  //               parseFloat(paymentstage.payment_amount) +
  //               parseFloat(paymentstage.tax_amount)
  //             ).toFixed(2)}
  //           </p>
  //         </Col>
  //       </Row>
  //     </>
  //   );
  // };

  const OfflinepaymentBody = ({ paymentstage, country_configuration }) => {
    // console.log(paymentstage);
    // console.log(country_configuration);
    return (
      <>
        <Row>
          <Col>
            <p className="pay-p-font">Items Total</p>
          </Col>
          <Col>
            <p className="pay-p-font pay-f-r">
              {country_configuration.currency}{" "}
              {/* {parseFloat(paymentstage.payment_amount).toFixed(2)} */}
              {parseFloat(
                changedPaymentAmount / (1 + paymentstage.tax_percentage / 100)
              ).toFixed(2)}
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="pay-p-font">
              Tax{" "}
              {paymentstage.tax_percentage
                ? ` (${paymentstage.tax_percentage}%)`
                : ""}
            </p>
          </Col>
          <Col>
            <p className="pay-p-font pay-f-r">
              {country_configuration.currency}{" "}
              {paymentstage.tax_amount
                ? // ? parseFloat(paymentstage.tax_amount).toFixed(2)
                  parseFloat(
                    ((changedPaymentAmount /
                      (1 + paymentstage.tax_percentage / 100)) *
                      paymentstage.tax_percentage) /
                      100
                  ).toFixed(2)
                : 0}
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p className="pay-p-font">Total Amount</p>
          </Col>
          <Col>
            <p className="pay-p-font pay-f-r">
              {country_configuration.currency}{" "}
              {paymentstage.payment_amount
                ? //  (
                  //   parseFloat(paymentstage.payment_amount) +
                  //   parseFloat(paymentstage.tax_amount)
                  // ).toFixed(2)
                  changedPaymentAmount
                : 0}
            </p>
          </Col>
        </Row>
      </>
    );
  };

  const TransactionHeader = ({
    transaction_history_data,
    order_id,
    country_configuration,
  }) => {
    let payment_amount = transaction_history_data.payment_amount
      ? Number(transaction_history_data.payment_amount)
      : 0;
    let tax_amount = transaction_history_data.tax_amount
      ? Number(transaction_history_data.tax_amount)
      : 0;

    return (
      <>
        <Row>
          <Col>
            {localStorage.setItem(
              "bbb",
              transaction_history_data.order_payment_stage
            )}
            <p className="pay-s-font pay-s-color">
              Order ID{" "}
              <span className="pay-p-font pay-p-color pay-ml10">
                {order_id}
              </span>
            </p>
            <p className="pay-s-font pay-s-color">
              {transaction_history_data.payment_type === "CUSTOMER_REFUND" ? (
                <>
                  Refunded on{" "}
                  {formatDateWithFormat(
                    transaction_history_data.refunded_on,
                    "Do MMM YYYY",
                    selectedTimeZone
                  )}
                </>
              ) : (
                <>
                  Paid on{" "}
                  {formatDateWithFormat(
                    transaction_history_data.paid_on,
                    "Do MMM YYYY",
                    selectedTimeZone
                  )}
                </>
              )}
            </p>
          </Col>
          <div className="col-lg-5">
            <p className="pay-s-font pay-s-color">
              <h6>Transaction Type :</h6>
              <span className="pay-p-font pay-p-color order-fl">
                {transaction_history_data.payment_type == "DB"
                  ? "HyperPay Transaction"
                  : transaction_history_data.payment_type ===
                    "INTERNAL_TRANSFER"
                  ? "Internal Transfer Transaction"
                  : transaction_history_data.payment_type === "CASH"
                  ? "Cash"
                  : transaction_history_data.payment_type === "CHEQUE"
                  ? "Cheque"
                  : transaction_history_data.payment_type === "NET_BANKING"
                  ? "Net Banking"
                  : transaction_history_data.payment_type === "CREDIT_CARD"
                  ? "Credit Card"
                  : transaction_history_data.payment_type === "CUSTOMER_REFUND"
                  ? "Customer Refund"
                  : transaction_history_data.payment_type === "IBAN"
                  ? "IBAN Transaction"
                  : "-"}
              </span>
            </p>
          </div>
          <div className="col-lg-3 rem_oo">
            <p className="pay-p-font pay-f-r pay-mt15">
              {country_configuration.currency}{" "}
              {parseFloat(payment_amount + tax_amount).toFixed(2)}
            </p>
            <>
              {transaction_history_data.e_invoice_url &&
              transaction_history_data.payment_type !== "CUSTOMER_REFUND" ? (
                <>
                  {moment(moment().format("YYYY-MM-DD")).isSameOrBefore(
                    "2022-11-23"
                  ) && adminType === "super" ? (
                    <>
                      {transaction_history_data.payment_type !==
                      "INTERNAL_TRANSFER" ? (
                        <div className="gen_poldwnInv2">
                          <a>
                            <span
                              className="gen_poldwnInv1"
                              onClick={() =>
                                deleteInvoice(
                                  transaction_history_data._id,
                                  transaction_history_data.order_id
                                )
                              }
                            >
                              Delete
                            </span>
                          </a>
                          <a
                            href={transaction_history_data.e_invoice_url}
                            download
                            target="_blank"
                          >
                            <span className="gen_poldwnInv1">Download</span>
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {transaction_history_data.payment_type !==
                        "INTERNAL_TRANSFER" &&
                      transaction_history_data.payment_type !==
                        "CUSTOMER_REFUND" &&
                      (adminType === "super" ||
                        adminType === "area_manager" ||
                        accountIds.onder === admin_id ||
                        accountIds.adreesFinanceTeam === admin_id) ? (
                        <a
                          href={transaction_history_data.e_invoice_url}
                          download
                          target="_blank"
                        >
                          <span className="gen_poldwnInv">
                            Download Invoice
                          </span>
                        </a>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {loader == true ? (
                    <div className="loader_right_pay fix_lod">
                      <ClipLoader color={"#242E49"} loading={loader} />
                    </div>
                  ) : (
                    <>
                      {transaction_history_data.payment_type !==
                        "INTERNAL_TRANSFER" &&
                      transaction_history_data.payment_type !==
                        "CUSTOMER_REFUND" &&
                      // (adminType === "super" ||
                      //   accountIds.adreesFinanceTeam === admin_id) ? (
                      (adminType === "super") ? (
                        <span
                          className="gen_pol"
                          onClick={() =>
                            generateInvoiceHandler(
                              transaction_history_data.order_payment_stage,
                              transaction_history_data._id,
                              transaction_history_data.tax_amount,
                              transaction_history_data.payment_amount,
                              transaction_history_data?.billing_vat_number
                            )
                          }
                        >
                          Generate Invoice
                        </span>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </div>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className="nu-mb15">
        <Col className="payment-card-action">
          <Card
            className={classnames({
              "card-collapsed": status === "Closed",
              "card-shown": status === "Opened",
              closing: status === "Closing...",
              opening: status === "Opening...",
            })}
          >
            <CardHeader>
              <CardTitle>
                {type == "item_total" ? (
                  <ItemTotalHeader
                    order_id={order_id}
                    draft={draft}
                    order={order}
                    otherOrder={otherOrder}
                    country_configuration={country_configuration}
                  />
                ) : type == "offline_payment" ? (
                  // <OfflinepaymentHeader
                  //   paymentstage={paymentstage}
                  //   country_configuration={country_configuration}
                  // />
                  <>
                    <Row>
                      <Col>
                        <p className="pay-s-font pay-s-color">Order ID</p>
                        <p className="pay-p-font">{paymentstage.order_id}</p>
                      </Col>
                      <Col>
                        <p className="pay-p-font pay-f-r pay-mt15">
                          {country_configuration.currency}{" "}
                          <Input
                            type="number"
                            min={1}
                            onChange={offlinePaymentAmountValueChange}
                            value={changedPaymentAmount}
                            // disabled={paymentstage.payment_for === 'second_half_complete'}
                            className="font_fix"
                          />
                          {/* {(
                        parseFloat(paymentstage.payment_amount) +
                        parseFloat(paymentstage.tax_amount)
                      ).toFixed(2)} */}
                        </p>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <TransactionHeader
                    transaction_history_data={transaction_history_data}
                    order_id={order_id}
                    country_configuration={country_configuration}
                  />
                )}
              </CardTitle>
              <ChevronDown
                className="collapse-icon new_fixda"
                size={15}
                onClick={() => setCollapse(!collapse)}
              />
            </CardHeader>
            <Collapse
              isOpen={collapse}
              onExited={() => setStatus("Closed")}
              onEntered={() => setStatus("Opened")}
              onExiting={() => setStatus("Closing...")}
              onEntering={() => setStatus("Opening...")}
            >
              <CardBody>
                {type == "item_total" ? (
                  <ItemTotalBody
                    draft={draft}
                    order={order}
                    country_configuration={country_configuration}
                  />
                ) : type == "offline_payment" ? (
                  <OfflinepaymentBody
                    paymentstage={paymentstage}
                    country_configuration={country_configuration}
                  />
                ) : (
                  <TransactionBody
                    transaction_history_data={transaction_history_data}
                    country_configuration={country_configuration}
                  />
                )}
              </CardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>
      <VatWarningModal
        vatWarningModal={vatWarningModal}
        setVatWarningModal={setVatWarningModal}
        generateInvoicePayload={generateInvoicePayload}
        setGenerateInvoicePayload={setGenerateInvoicePayload}
        ClickgenerateInvoice={ClickgenerateInvoice}
      />
    </>
  );
};
export default PaymentCollapsibleComponent;
